import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { buildValidateCellUrl } from 'state-domains/network';

import { sendRequestWithXSRFToken } from '../../../utils';

export const validateCell = (payload: any): Observable<any> => {
    const obs = sendRequestWithXSRFToken(buildValidateCellUrl(), payload);
    return obs.pipe(switchMap(() => observableOf({})));
};
